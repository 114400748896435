import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut, selectAuth } from "../features/authSlice";
import moon from "../assets/img/moon.png";
import user from "../assets/img/user.png";
import { useEffect, useRef, useState } from "react";

function MenuBar() {
  const profileNode: any = useRef();
  const menuDropdownNode: any = useRef();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [menuSwitch, setMenuSwitch] = useState(false);
  const { name } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const Logout = () => {
    dispatch(logOut());
    navigate("/auth");
  };

  const profileMenuclickOutside = (e: any) => {
    if (profileNode.current.contains(e.target)) {
      return;
    }
    setShowProfileMenu(false);
  };

  const dropDownMenuclickOutside = (e: any) => {
    if (menuDropdownNode.current.contains(e.target)) {
      return;
    }
    setMenuSwitch(false);
  };

  const toggleProfileMenu = (users: boolean) => {
    return setShowProfileMenu(!showProfileMenu);
  };

  useEffect(() => {
    document.addEventListener("mousedown", profileMenuclickOutside);
    return () => {
      document.removeEventListener("mousedown", profileMenuclickOutside);
    };
  }, [showProfileMenu]);

  useEffect(() => {
    document.addEventListener("mousedown", dropDownMenuclickOutside);
    return () => {
      document.removeEventListener("mousedown", dropDownMenuclickOutside);
    };
  }, [menuSwitch]);

  return (
    <nav className="w-full fixed top-0 bg-white border-b-[1px] border-gray-300 px-2 md:px-5 xl:px-5 z-10">
      <div className="xl:w-xl px-1 md:px-2 mx-auto flex items-center justify-between relative">
        {/* Left */}
        <div className="flex items-center justify-between">
          <div className="py-4">
            <h1
              className="text-center text-xl text-gray-800 font-semibold tracking-wide cursor-pointer"
              onClick={() => navigate("/dashboard")}
            >
              Mohmaya.
            </h1>
          </div>

          <ul className="hidden md:flex text-gray-700 text-bold text-md ml-10 pt-1  md:space-x-5 lg:space-x-10">
            <li
              className={`py-4 px-1 cursor-pointer border-b-gray-400 ${
                location.pathname === "/dashboard"
                  ? " border-b-[1px]"
                  : "hover:border-b-[1px]"
              }`}
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </li>
            <li
              className={`py-4 px-1 cursor-pointer border-b-gray-400 ${
                location.pathname === "/expenses"
                  ? " border-b-[1px]"
                  : "hover:border-b-[1px]"
              }`}
              onClick={() => navigate("/expenses")}
            >
              Expenses
            </li>
            <li
              className={`py-4 px-1 cursor-pointer border-b-gray-400 ${
                location.pathname === "/transfers"
                  ? " border-b-[1px]"
                  : "hover:border-b-[1px]"
              }`}
              onClick={() => navigate("/transfers")}
            >
              Transfers
            </li>
            <li
              className={`py-4 px-1 cursor-pointer border-b-gray-400 ${
                location.pathname === "/income"
                  ? " border-b-[1px]"
                  : "hover:border-b-[1px]"
              }`}
              onClick={() => navigate("/income")}
            >
              Income
            </li>
            <li
              className={`py-4 px-1 cursor-pointer border-b-gray-400 ${
                location.pathname === "/preferences"
                  ? " border-b-[1px]"
                  : "hover:border-b-[1px]"
              }`}
              onClick={() => navigate("/preferences")}
            >
              Preferences
            </li>
          </ul>
        </div>

        {/* Right */}
        <div className="hidden md:flex items-center justify-between space-x-5">
          <img
            id="moon"
            src={moon}
            className="hidden md:block w-5 cursor-pointer"
            alt=""
          />
          <div
            className="flex justify-between items-center bg-gray-100 hover:bg-gray-200 rounded-r-xl rounded-l-xl shadow-sm cursor-pointer overflow-hidden"
            ref={profileNode}
            onClick={() => {
              toggleProfileMenu(showProfileMenu);
            }}
          >
            <img
              src={user}
              alt=""
              className="rounded-full ring-1 ring-gray-400"
              width={30}
              height={30}
            />
            <div className="text-gray-700 text-md p-1 pl-2 pr-3">{name}</div>
            <div
              className={`absolute top-14 right-3 shadow-md rounded-sm ring-gray-200 ring-1 bg-white ${
                showProfileMenu ? "block" : "hidden"
              }`}
            >
              <div className="py-2 text-sm">
                <ul className="text-gray-600 text-md cursor-pointer">
                  <li
                    className="py-1 px-4 hover:bg-gray-100 hover:text-gray-700"
                    onClick={() => {
                      console.log("Profile");
                    }}
                  >
                    Profile
                  </li>
                  <li
                    className="py-1 px-4 hover:bg-gray-100 hover:text-gray-700"
                    onClick={() => {
                      navigate("/preferences");
                    }}
                  >
                    Settings
                  </li>
                  <li
                    className="py-1 px-4 hover:bg-gray-100 hover:text-gray-700"
                    onClick={() => Logout()}
                  >
                    Logout
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          id="hamburger"
          className="md:hidden space-y-1 cursor-pointer"
          onClick={() => setMenuSwitch(!menuSwitch)}
          onBlur={() => setMenuSwitch(false)}
          onFocus={() => setMenuSwitch(true)}
          ref={menuDropdownNode}
        >
          <div className="w-6 h-0.5 bg-black"></div>
          <div className="w-6 h-0.5 bg-black"></div>
          <div className="w-6 h-0.5 bg-black"></div>

          {menuSwitch && (
            <ul className="absolute right-0 top-14 w-full pt-0 rounded-b-xl text-gray-900 bg-white text-center shadow-md">
              <li
                className="py-2 border-b-[1px] border-b-gray-100 cursor-pointer hover:bg-gray-100"
                onClick={() => navigate("/dashboard")}
              >
                Dashboard
              </li>
              <li
                className="py-2 border-b-[1px] border-b-gray-100 cursor-pointer hover:bg-gray-100"
                onClick={() => navigate("/expenses")}
              >
                Expenses
              </li>
              <li
                className="py-2 border-b-[1px] border-b-gray-100 cursor-pointer hover:bg-gray-100"
                onClick={() => navigate("/transfers")}
              >
                Transfers
              </li>
              <li
                className="py-2 border-b-[1px] border-b-gray-100 cursor-pointer hover:bg-gray-100"
                onClick={() => navigate("/income")}
              >
                Income
              </li>
              <li
                className="py-2 border-b-[1px] border-b-gray-100 cursor-pointer hover:bg-gray-100"
                onClick={() => navigate("/preferences")}
              >
                Preferences
              </li>
              <li
                className="py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => Logout()}
              >
                Logout
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
}

export default MenuBar;
