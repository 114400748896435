import MenuBar from "../components/MenuBar";

type Props = {
  children: JSX.Element;
};

function UserLayout({ children }: Props) {
  return (
    <>
      {/* <div className="h-screen bg-gradient-to-t from-blue-100 overflow-auto"> */}
      {/* <div className="h-screen bg-gradient-to-t from-bgColor overflow-auto"> */}
      <div className="h-screen bg-bgColor overflow-auto">
        <MenuBar />
        <div className="w-full xl:w-xl m-auto">
          <div className="mt-16 md:mt-20 px-2 md:px-5 xl:px-0">{children}</div>
        </div>
      </div>
    </>
  );
}

export default UserLayout;
