import { Button } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { iEntity } from "../../app/interfaces/iEntityState";
import { ToastError, ToastSuccess } from "../../util/Toast";
import { useForm, SubmitHandler } from "react-hook-form";
import { iIncomeSource } from "../../app/interfaces/iIncomeState";
import { useCreateExpenseTypeMutation } from "../../services/expenseApi";
import {
  addExpenseTypes,
  editUpdateExpenseTypes,
} from "../../features/xpenseSlice";
import { iExpenseType } from "../../app/interfaces/iExpenseState";

interface IFormInput {
  name: string;
  parent: number;
  in_trends: number;
}

type Props = {
  showNewExpense: boolean;
  setShowNewExpense: (val: boolean) => void;
  incomeSource?: iIncomeSource[];
  entities?: iEntity[];
  exTypes: iExpenseType[];
  selectedExpenseType: iExpenseType | undefined;
  onProgress: Dispatch<SetStateAction<number>>;
};

function AddExpenseType({
  showNewExpense,
  setShowNewExpense,
  incomeSource,
  entities,
  exTypes,
  selectedExpenseType,
  onProgress,
}: Props) {
  const dispatch = useAppDispatch();
  const [createExpenseType, { data, isSuccess, isError, isLoading, error }] =
    useCreateExpenseTypeMutation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    //console.log(data);
    await createExpenseType({
      id: selectedExpenseType ? selectedExpenseType.id : 0,
      expenseType: data.name,
      parent: data.parent,
      in_trends: data.in_trends,
    });
  };

  useEffect(() => {
    if (selectedExpenseType?.name) setValue("name", selectedExpenseType?.name);
    if (selectedExpenseType?.in_trends)
      setValue("in_trends", selectedExpenseType?.in_trends ? 1 : 0);
    if (selectedExpenseType?.parent)
      setValue("parent", Number(selectedExpenseType?.parent));
  }, [selectedExpenseType]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      const newData_: iExpenseType = data.data[0];
      if (selectedExpenseType && newData_) {
        dispatch(editUpdateExpenseTypes({ expenseType: newData_ }));
      } else if (newData_) {
        dispatch(addExpenseTypes({ expenseType: newData_ }));
      }
      setShowNewExpense(!showNewExpense);
      onProgress(100);
      ToastSuccess(
        1,
        `Expense type ${selectedExpenseType ? "updated" : "added"} successfully`
      );
    }
    if (isError) {
      ToastError(
        2,
        `Cannot ${
          selectedExpenseType ? "update" : "add new"
        } expense type, please try again: ` + Error
      );
      console.log("Error: ", Error);
    }
  }, [isSuccess, isError]);

  return (
    <div className="">
      <Dialog
        open={showNewExpense}
        handler={setShowNewExpense}
        className="min-w-5/6 sm:min-w-3/4 md:min-w-fit"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader className="text-gray-1000 text-lg text-gray-900">
            {selectedExpenseType
              ? "Update Expense Type(" + selectedExpenseType.name + ")"
              : "New Expense Type"}
          </DialogHeader>

          <DialogBody divider>
            <div className="w-full text-gray-900 text-sm bg-white m-2">
              <div className="mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5"
                >
                  Name
                </label>
                <input
                  type="text"
                  {...register("name", { required: true })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">{errors?.name?.type}</p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Parent
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                    "paymentType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("parent", {})}
                >
                  <option value="0">None</option>
                  {exTypes.map(
                    (item, n) =>
                      item.id != selectedExpenseType?.id && (
                        <option value={item.id} key={n}>
                          {item.name}
                        </option>
                      )
                  )}
                </select>
                <p className="text-red-900 text-right">
                  {errors?.parent?.type}
                </p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  In Trends
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                    "paymentType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("in_trends", {})}
                >
                  <option value="0" key="0">
                    No
                  </option>
                  <option value="1" key="1">
                    Yes
                  </option>
                </select>
                <p className="text-red-900 text-right">
                  {errors?.in_trends?.type}
                </p>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            {/* <Button
              variant="gradient"
              color="red"
              onClick={() => setShowNewExpense(!showNewExpense)}
              className="mr-1"
              size="sm"
              disabled
            >
              <span>Delete</span>
            </Button> */}
            <Button
              variant="text"
              color="red"
              onClick={() => setShowNewExpense(!showNewExpense)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="blue" type="submit">
              <span>{selectedExpenseType ? 'Update' : 'Add' }</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  );
}

export default AddExpenseType;
