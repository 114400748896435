import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../app/mohmayaApi';

export const incomeApi = createApi({
  reducerPath: 'incomeApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createIncomeSource: builder.mutation({
      query: (body: {id: number, name: string, amount: number, payroll_type: string, auto_transaction: string}) => {
        return {
          url: '/income-source/add',
          method: 'post',
          body
        }
      }
    }),
    getIncomeSources: builder.mutation({
      query: () => {
        return {
          url: '/income-sources',
          method: 'get',
        }
      }
    }),
    createIncome: builder.mutation({
      query: (body: {paymentMethod:string, incomeSource:string, amount:number, note:string, date: string, payAccounts: any[], saveForm: boolean}) => {
        return {
          url: '/income/add',
          method: 'post',
          body
        }
      }
    }),
    getIncome: builder.mutation({
      query: () => {
        return {
          url: '/income',
          method: 'get',
        }
      }
    }),
  }),
})


export const { useCreateIncomeSourceMutation, useGetIncomeSourcesMutation, useCreateIncomeMutation, useGetIncomeMutation } = incomeApi;