import React, { useState } from "react";

function SplashScreen() {
  const [dot1, setDot1] = useState(false);
  const [dot2, setDot2] = useState(true);

  setTimeout(() => {
    setDot1(!dot1);
    setDot2(!dot2);
  }, 500);

  return (
    <div className="flex flex-col h-screen  bg-gradient-to-t from-bgColor justify-center items-center">
      <div className="p-2 w-full flex justify-center">
        <span className="text-center text-4xl text-gray-800 font-semibold tracking-wide align-top inline-block">
          Mohmaya {dot1 && "."} {dot2 && ".."}
        </span>
      </div>
    </div>
  );
}

export default SplashScreen;
