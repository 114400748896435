type props = {
  val: number;
};

function Currency({ val }: props) {
  let res = "0";

  const getNatural = (num: number) => {
    return parseFloat(num.toString().split(".")[0]);
  };

  const getDecimal = (num: number) => {
    return parseFloat(num.toString().split(".")[1]);
  };

  const decNum = getDecimal(val);
  res = getNatural(val).toLocaleString("en-US");
  if (decNum) {
    const d = decNum.toString().length === 1 ? decNum + "0" : decNum;
    if (d.toString().length <= 2) {
      res = res + "." + d;
    } else {
      const split_d = d.toString().split("");
      res = res + "." + split_d[0] + "" + split_d[1];
    }
  } else {
    res = res + "." + "00";
  }

  return <div>${res}</div>;
}

export default Currency;
