import { useSelector } from 'react-redux';
import { selectExpense } from '../../features/xpenseSlice';
import { useEffect, useState } from 'react';
import { iTransaction } from '../../app/interfaces/iTransaction';
import { iExpenseType } from '../../app/interfaces/iExpenseState';
import { toFullMonth, toMonth } from '../../constants/date';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut, getElementsAtEvent } from 'react-chartjs-2';
import { useRef } from 'react';
import { formatCurrency } from '../../util/Common';
import { chartBorderCode, chartColorCode } from '../../constants/chartColor';

function MonthlyExpenseChart() {
  const exTypes = useSelector(selectExpense);
  const expense = useSelector(selectExpense);
  const [expenseType, setExpenseType ] = useState<iExpenseType[]>();
  let expenseListAll: iTransaction[] = expense.expenses;
  expenseListAll = expenseListAll.filter((item) => {
    return item.type === "expense";
  });
  const [exTypesList, setExTypesList] = useState<iExpenseType[]>(
    exTypes.expenseTypes || []
  );
  const [chartData, setChartData] = useState<any>([]);
  const [chartYears, setChartYears] = useState<any>([]);
  const [currenMonthExpense, setCurrenMonthExpense] = useState<{month:number, name: string, total: string}>();
  const [categorySpendings, setCategorySpendings] = useState<any>([]);
  const [donutItemName, setDonutItemName] = useState<any>([]);
  const [donutItemValue, setDonutItemValue] = useState<any>([]);
  const [donutItemColor, setDonutItemColor] = useState<any>([]);
  const [donutItemBColor, setDonutItemBColor] = useState<any>([]);

  ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

  useEffect(() => {
    //Extypes filter
    let filteredExTypes:iExpenseType[] = [];
    exTypesList.map((item) => {
      if(item.in_trends) {
        filteredExTypes.push(item);
      }
    });
    setExpenseType(filteredExTypes);

    let data: {}[] = [];
    expenseListAll.forEach((item) => {
      const d = new Date(item.createdAt);
    });

    toMonth.forEach((month, m) => {
      let obj = {};
      let totalAll = 0;
      const year = new Date().getFullYear();
      exTypesList.forEach((exType) => {
        let total = 0;
        expenseListAll.forEach((expense) => {
          const dt = new Date(expense.createdAt);
          if(dt.getUTCMonth() === m && dt.getUTCFullYear() == year && expense.expenseType.id === exType.id && exType.in_trends) {
            total += Number(expense.amount);
          }
        });
        obj = {...obj, [`${exType.name}`]: total}
        totalAll += Number(total.toFixed(2));
      });
      if(totalAll > 0) {
        obj = {...obj, [`name`]: month}
        //data.push(obj);
      }
    });
    //setChartData(data);

    //chart years
    let chartYearsTmp: {}[] = [];
    //setting bar graph data
    toMonth.forEach((month, m) => {
      let total = 0;
      const year = new Date().getFullYear();
      const currentMonth = new Date().getUTCMonth();
      expenseListAll.forEach((expense) => {
        const dt = new Date(expense.createdAt);
        if(dt.getUTCMonth() === m && dt.getUTCFullYear() == year) {
          total += Number(expense.amount);
        }
      });
      data.push(total.toFixed(2));
      if(total > 0) {
        chartYearsTmp.push(month);
      }
      if(m === currentMonth) {
        setCurrenMonthExpense({
          month: m,
          name: toFullMonth[m],
          total: '$' + formatCurrency(Number(data[m])).toString()
        });
      }
    });
    setChartData(data);
    setChartYears(chartYearsTmp);

  },[exTypes, expense]);

  useEffect(() => {
    let data:{}[] = [];
    let obj = {};
    let totalAll = 0;
    const year = new Date().getFullYear();
    exTypesList.forEach((exType) => {
      let total = 0;
      expenseListAll.forEach((expense) => {
        const dt = new Date(expense.createdAt);
        if(dt.getUTCMonth() === currenMonthExpense?.month && dt.getUTCFullYear() == year && expense.expenseType.id === exType.id) {
          total += Number(expense.amount);
        }
      });
      totalAll += Number(total.toFixed(2));

      if(totalAll > 0 && total > 0) {
        obj = {...obj, [`name`]: exType.name}
        obj = {...obj, [`total`]: total}
        data.push(obj);
      }
    });
    setCategorySpendings(data);

    //donut chart
    let dn:any = [];
    let dv:any = [];
    let dc:any = [];
    let db:any = [];
    data.forEach((item:any,i) => {
      let percentage = Number(((item.total * 100 )/totalAll).toFixed());
      dn.push(item.name);
      dv.push(percentage);
      dc.push(chartColorCode[i]);
      db.push(chartBorderCode[i]);
    });
    setDonutItemName(dn);
    setDonutItemValue(dv);
    setDonutItemColor(dc);
    setDonutItemBColor(db);


  },[currenMonthExpense]);

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: function () {
            return "";
          },
          label: (context: any) => {
            let label = "";
            if (context.parsed.y) {
              label =  " $" + context.parsed.y
            }
            return label;
          }
        }
      },
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Monthly Expenses',
      },
    },
    scales: {
      y: {
        min: 0,
        ticks: {
          callback: function (value:any) {
            if(value.toString() === '0') {
              return '0';
            } else {
              return `${value/1000}k`;
            }
        },
        },
      },
    },
  };

  const donutOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom' as const,
      },
    },
  };


  const labels = chartYears;

  const data = {
    labels,
    datasets: [
      {
        label: 'Expense',
        data: chartData,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }
    ],
  };

  const donutData = {
    labels: donutItemName,
    datasets: [
      {
        label: '%',
        data: donutItemValue,
        backgroundColor: donutItemColor,
        borderColor: donutItemBColor,
        borderWidth: 1,
      },
    ],
  };

  const chartRef = useRef();
  const onClickBar = (event:any) => {
    if(chartRef.current && getElementsAtEvent(chartRef.current, event).length > 0) {
      //const datasetIndexNum = getElementsAtEvent(chartRef.current, event)[0].datasetIndex;
      const datasetPoint = getElementsAtEvent(chartRef.current, event)[0].index;

      setCurrenMonthExpense({
        month: datasetPoint,
        name: toFullMonth[datasetPoint],
        total: '$' + formatCurrency(Number(chartData[datasetPoint])).toString()
      });
    }
    
  }

  return (
    <div className='bg-white rounded-lg shadow-sm my-1.5 md:my-2.5 p-3 md:p-5 border-[1px] border-bdColor'>
      <div className="text-titleColor font-thin">
        {currenMonthExpense && currenMonthExpense.name } spending {currenMonthExpense && currenMonthExpense.total } 
      </div>
      <div className="pt-2 md:pt-2">
        <Bar options={options} data={data} onClick={onClickBar} ref={chartRef} />
      </div>

      <div className='pt-2 md:pt-5 md:px-5'>
        <div className="text-titleColor font-thin px-1">
        {currenMonthExpense && currenMonthExpense.name } categories
        </div>
        <div className='my-2 bg-gray-50 text-center p-3 rounded-lg text-gray-600 font-thin text-sm'>
          Your spending was {currenMonthExpense && currenMonthExpense.total } in {currenMonthExpense && currenMonthExpense.name }
        </div>
      </div>


      <div className='pt-2 md:pt-2 md:px-5 md:flex items-center justify-center'>
        <div className='w-full md:w-1/2 md:px-10 lg:px-20 hidden md:block'>
          <Doughnut data={donutData} options={donutOptions}/>
        </div>
        <div className='w-full md:w-1/2'>
          <div className='w-full text-sm'>
            {categorySpendings && categorySpendings.length > 0 && categorySpendings.map((item:any, i:number) => ( item.total > 0 && 
              <div className='flex justify-between py-1.5 px-2 border-b-[1px]'>
                <div className='flex w-auto' key={i}>
                  <div className={`mt-1.5 md:mt-1.5 w-1 h-1 md:w-2 md:h-2 bg-color-${i} rounded-sm`}></div>
                  <div className={`px-2 text-color-${i} overflow-auto overflow-x-hidden w-auto`}>{item.name}</div>
                </div>
                <div className='text-gray-600'>
                  ${formatCurrency(item.total)}
                </div>
              </div>
            ))}
          </div>
        </div>
        </div>
    </div>
  )
}

export default MonthlyExpenseChart