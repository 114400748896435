export const formatDay = (day:any) => {
  if(String(day) === '1') {
    return '1st';
  } else if(String(day) === '2') {
    return '2nd';
  } else if(String(day) === '3') {
    return '3rd';
  } else {
    return day + 'th';
  }
}
export const formatRecurring = (n:number) => {
  if(String(n) === '1') {
    return 'Every Month';
  } else if(String(n) === '2') {
    return 'Every 6 Months';
  } else if(String(n) === '3') {
    return 'Every Year';
  } else {
    return 'none';
  }
}

const getDecimal = (num: number) => {
  return parseFloat(num.toString().split(".")[1]);
};

const getNatural = (num: number) => {
  return parseFloat(num.toString().split(".")[0]);
};
export const formatCurrency = (n:number) => {
  let res = "0";
  const decNum = getDecimal(n);
  res = getNatural(n).toLocaleString("en-US");
  if (decNum) {
    const d = decNum.toString().length === 1 ? decNum + "0" : decNum;
    if (d.toString().length <= 2) {
      res = res + "." + d;
    } else {
      const split_d = d.toString().split("");
      res = res + "." + split_d[0] + "" + split_d[1];
    }
  } else {
    res = res + "." + "00";
  }
  return res;
}