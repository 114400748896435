import { iIncomeSource } from './../app/interfaces/iIncomeState';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iIncomeState } from "../app/interfaces/iIncomeState";
import { iTransaction } from "../app/interfaces/iTransaction";
import { RootState } from "../app/store";


const initialState: iIncomeState = {
  income: [],
  incomeSource: [],
}

export const incomeSlice = createSlice({
  name: 'income',
  initialState: initialState,
  reducers: {
    setIncome: (state, action: PayloadAction<{income: iTransaction[]}>) => {
      state.income = action.payload.income
    },
    addIncome: (state, action: PayloadAction<{income: iTransaction}>) => {
      state.income.unshift(action.payload.income)
    },

    setIncomeSource: (state, action: PayloadAction<{incomeSource: iIncomeSource[]}>) => {
      state.incomeSource = action.payload.incomeSource
    },

    editUpdateIncomeSource: (state, action: PayloadAction<{incomeSource: iIncomeSource}>) => {
      const updatedIncomeSource = state.incomeSource.map((item, i) => {
        if(item.id === action.payload.incomeSource.id) {
          item = action.payload.incomeSource;
        }
        return item;
      })
      state.incomeSource = updatedIncomeSource;
    },

    addIncomeSource: (state, action: PayloadAction<{incomeSource: iIncomeSource}>) => {
      state.incomeSource.unshift(action.payload.incomeSource)
    },
  }
})

export const selectIncome = (state: RootState) => state.income
export const { setIncome, addIncome, setIncomeSource, addIncomeSource, editUpdateIncomeSource } = incomeSlice.actions
export default incomeSlice.reducer