import { Button } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { iEntity } from "../../app/interfaces/iEntityState";
import { ToastError, ToastSuccess } from "../../util/Toast";
import { useForm, SubmitHandler } from "react-hook-form";
import { iIncomeSource } from "../../app/interfaces/iIncomeState";
import { useCreateIncomeSourceMutation } from "../../services/incomeApi";
import {
  addIncomeSource,
  editUpdateIncomeSource,
} from "../../features/incomeSlice";
import { payrollType } from "../../app/shared";

interface IFormInput {
  name: string;
  amount: number;
  payroll_type: string;
  auto_transaction: string;
}

type Props = {
  showNewExpense: boolean;
  setShowNewExpense: (val: boolean) => void;
  incomeSource?: iIncomeSource[];
  entities?: iEntity[];
  selectedIncomeSource?: iIncomeSource;
  onProgress: Dispatch<SetStateAction<number>>;
};

function AddIncomeSource({
  showNewExpense,
  setShowNewExpense,
  selectedIncomeSource,
  onProgress,
}: Props) {
  const dispatch = useAppDispatch();
  const [createIncomeSource, { data, isSuccess, isError, isLoading, error }] =
    useCreateIncomeSourceMutation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    //console.log(data);
    await createIncomeSource({
      id: selectedIncomeSource ? selectedIncomeSource.id : 0,
      name: data.name,
      amount: data.amount,
      payroll_type: data.payroll_type,
      auto_transaction: data.auto_transaction,
    });
  };

  useEffect(() => {
    setValue("name", selectedIncomeSource ? selectedIncomeSource.name : "");
    setValue(
      "payroll_type",
      selectedIncomeSource ? selectedIncomeSource.payroll_type : ""
    );
    setValue(
      "auto_transaction",
      selectedIncomeSource ? selectedIncomeSource.auto_transaction : ""
    );
    setValue(
      "amount",
      selectedIncomeSource ? Number(selectedIncomeSource.amount) : 0
    );
  }, [selectedIncomeSource]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      const newData_: iIncomeSource = data.data[0];
      if (data && selectedIncomeSource) {
        dispatch(editUpdateIncomeSource({ incomeSource: newData_ }));
      } else if (data) {
        dispatch(addIncomeSource({ incomeSource: newData_ }));
      }
      setShowNewExpense(!showNewExpense);
      onProgress(100);
      ToastSuccess(
        1,
        `Income source ${
          selectedIncomeSource ? "updated" : "added"
        } successfully`
      );
    }
    if (isError) {
      ToastError(
        2,
        `Cannot ${
          selectedIncomeSource ? "update" : "add new"
        } income source, please try again: ` + Error
      );
      console.log("Error: ", Error);
    }
  }, [isSuccess, isError]);

  return (
    <div className="">
      <Dialog
        open={showNewExpense}
        handler={setShowNewExpense}
        className="min-w-5/6 sm:min-w-3/4 md:min-w-fit"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader className="text-gray-1000 text-lg text-gray-900">
            {selectedIncomeSource
              ? "Update Income Source (" + selectedIncomeSource.name + ")"
              : "New Income Source"}
          </DialogHeader>

          <DialogBody divider>
            <div className="w-full text-gray-900 text-sm bg-white m-2">
              <div className="mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5"
                >
                  Name
                </label>
                <input
                  type="text"
                  {...register("name", { required: true })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">{errors?.name?.type}</p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Payroll Type
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                    "paymentType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("payroll_type", {})}
                >
                  {payrollType.map((item, n) => (
                    <option value={n} key={n}>
                      {item}
                    </option>
                  ))}
                </select>
                <p className="text-red-900 text-right">
                  {errors?.payroll_type?.type}
                </p>
              </div>
              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5"
                >
                  Fixed Salary(per period)
                </label>
                <input
                  type="number"
                  {...register("amount")}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">
                  {errors?.amount?.type}
                </p>
              </div>
              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5"
                >
                  Auto Transaction
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                    "paymentType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("auto_transaction", {})}
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
                <p className="text-red-900 text-right">
                  {errors?.auto_transaction?.type}
                </p>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => setShowNewExpense(!showNewExpense)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="blue" type="submit">
              <span>{selectedIncomeSource ? "Update" : "Add"}</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  );
}

export default AddIncomeSource;
