import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Auth from "./pages/Auth/index";
import Dashboard from "./pages/Dashbaord/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch } from "./app/hooks";
import { useEffect, useState } from "react";
import { setUser } from "./features/authSlice";
import { Provider } from "react-redux";
import React from "react";
import { store } from "./app/store";
import RequireAuth from "./features/requireAuth";
import Expenses from "./pages/Expenses/index";
import Preferences from "./pages/Preferences/index";
import LoadingBar from "react-top-loading-bar";
import Income from "./pages/Income";
import Transfers from "./pages/transfers/index";

function App() {
  const dispatch = useAppDispatch();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  dispatch(setUser(user));

  useEffect(() => {
    // refreshToken({refreshToken:'fdg'})
  });

  const [progress, setProgress] = useState(0);

  return (
    <div className="App">
      <React.StrictMode>
        <Provider store={store}>
          <LoadingBar
            color="#4994ec"
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/auth" element={<Auth />} />
              <Route element={<RequireAuth />}>
                <Route
                  path="/dashboard"
                  element={<Dashboard onProgress={setProgress} />}
                />
                <Route
                  path="/expenses"
                  element={<Expenses onProgress={setProgress} />}
                />
                <Route
                  path="/expenses/create-type"
                  element={<Expenses onProgress={setProgress} />}
                />
                <Route
                  path="/transfers"
                  element={<Transfers onProgress={setProgress} />}
                />
                <Route
                  path="/income"
                  element={<Income onProgress={setProgress} />}
                />
                <Route
                  path="/preferences"
                  element={<Preferences onProgress={setProgress} />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </Provider>
      </React.StrictMode>
    </div>
  );
}

export default App;
