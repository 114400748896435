import {
  faChevronLeft,
  faChevronRight,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { iEntity } from "../app/interfaces/iEntityState";
import { iTransaction } from "../app/interfaces/iTransaction";
import { iWidgetItem } from "../app/interfaces/iWidget";
import { toFullMonth, toMonth } from "../constants/date";
import Currency from "./Currency";
import useTransaction from "../app/hooks/useTransaction";
import { useSelector } from "react-redux";
import { selectExpense } from "../features/xpenseSlice";

type props = {
  expenseList: iTransaction[];
  entities: iEntity[];
};

function MonthlyTransfersWidget({ expenseList, entities }: props) {
  const [widgets, setWidgets] = useState<iWidgetItem[]>([]);
  const [total, setTotal] = useState(0);
  const month = new Date().getUTCMonth();
  const year = new Date().getUTCFullYear();
  const thisYear = new Date().getUTCFullYear();
  const [currentMonth, setCurrentMonth] = useState(month);
  const [currentYear, setCurrentYear] = useState(year);
  let total_ = 0;
  const [loadTransactions] = useTransaction();
  const expense = useSelector(selectExpense);
  const [reloadEffect, setReloadEffect] = useState(false);

  useEffect(() => {
    // fetching transactions from different year
    if (!expense.transactionYearsInState.includes(currentYear)) {
      loadTransactions(currentYear).finally(() => {
        setReloadEffect(!reloadEffect);
      });
    }
  }, [currentYear]);

  useEffect(() => {
    let subTotal = 0;
    setWidgets([]);
    total_ = 0;
    entities.map((item) => {
      subTotal = 0;
      expenseList.map((eItem) => {
        const createdAt = new Date(eItem.createdAt);
        if (
          item.id === eItem.financialEntity.id &&
          eItem.type === "transfer" &&
          createdAt.getUTCMonth() === currentMonth &&
          createdAt.getUTCFullYear() === new Date().getUTCFullYear()
        ) {
          subTotal = subTotal + Number(eItem.amount);
        }
      });
      if (subTotal > 0) {
        const i: iWidgetItem = {
          name: item.name ? item.name : "",
          val: subTotal,
        };
        setWidgets((arr) => [...arr, i]);
        total_ = total_ + subTotal;
      }
    });
    setTotal(total_);
  }, [currentMonth, currentYear, reloadEffect, expenseList]);

  const nextMonth = () => {
    if (currentMonth + 1 < 12) setCurrentMonth(currentMonth + 1);
    else {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(0);
    }
  };

  const prevMonth = () => {
    if (currentMonth > 0) setCurrentMonth(currentMonth - 1);
    else {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
    }
  };

  return (
    <div className=" select-none">
      <div className="mb-5 bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] border-bdColor">
        <div className="text-titleColor font-semibold flex flex-row justify-between">
          <div title={toFullMonth[new Date().getMonth()]} className=" font-thin">
            Monthly Transfers
          </div>
          <div className="font-thin text-xs text-gray-500 flex mt-1">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="mr-1 p-1 cursor-pointer hover:bg-gray-200 rounded-full"
              onClick={() => prevMonth()}
            />
            <div className="text-gray-800 p-0.5">
              {toMonth[currentMonth]}{" "}
              {thisYear !== currentYear && currentYear.toString().slice(-2)}
            </div>
            <FontAwesomeIcon
              icon={faChevronRight}
              className="ml-1 p-1 cursor-pointer hover:bg-gray-200 rounded-full"
              onClick={() => nextMonth()}
            />
          </div>
        </div>
        <div className="md:pt-4">
          <ul className="w-full text-gray-800">
            {widgets &&
              widgets.length > 0 &&
              widgets.map((item, i) => (
                <li
                  className="flex flex-row justify-between my-2 pb-2 border-b-2 border-b-gray-100 text-gray-700 text-sm tracking-wide"
                  key={i}
                >
                  <div className="">{item.name}</div>
                  <div>
                    <Currency val={item.val} />
                  </div>
                </li>
              ))}

            {widgets.length > 0 ? (
              <li className="flex flex-row justify-between mt-5 pb-2 text-gray-700 text-sm tracking-wide">
                <div className="font-semibold">Total</div>
                <div className="font-semibold">
                  <Currency val={total} />
                </div>
              </li>
            ) : (
              <p className="text-sm text-center font-thin text-gray-600">
                no transfers yet
              </p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MonthlyTransfersWidget;
