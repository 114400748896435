import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const location = useLocation();
  return user.name ? (
    <Outlet />
  ) : (
    <Navigate to="/auth" state={{ from: location }} replace />
  );
};

export default RequireAuth;
