import { toast } from 'react-toastify'

export const ToastSuccess = (id:number, message:string ) => {
  if(!toast.isActive(id)) { toast.success(message, { toastId:id, position: toast.POSITION.BOTTOM_RIGHT });}
}

export const ToastError = (id:number, message:string) => {
  if(!toast.isActive(id)) { toast.error(message, { toastId:id, position: toast.POSITION.BOTTOM_RIGHT  });}
}

export const ToastWarn = (id:number, message:string) => {
  if(!toast.isActive(id)) { toast.warn(message, { toastId:id, position: toast.POSITION.BOTTOM_RIGHT  });}
}