import { Button } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { iEntity } from "../../app/interfaces/iEntityState";
import { ToastError, ToastSuccess } from "../../util/Toast";
import { useForm, SubmitHandler } from "react-hook-form";
import { iIncomeSource } from "../../app/interfaces/iIncomeState";
import { entityType } from "../../app/shared";
import { useCreateEntityMutation } from "../../services/entityApi";
import { addEntity, editAndUpdateEntity } from "../../features/entitySlice";

interface IFormInput {
  name: string;
  type: string;
  balance: number;
  credit_limit: number;
  apr: string;
  due_date: string;
}

type Props = {
  showNewExpense: boolean;
  setShowNewExpense: (val: boolean) => void;
  incomeSource?: iIncomeSource[];
  entities?: iEntity[];
  selectedEntity?: iEntity;
  onProgress: Dispatch<SetStateAction<number>>;
};

function AddEntity({
  showNewExpense,
  setShowNewExpense,
  incomeSource,
  entities,
  selectedEntity,
  onProgress,
}: Props) {
  const dispatch = useAppDispatch();
  const [createEntity, { data, isSuccess, isError, isLoading, error }] =
    useCreateEntityMutation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  useEffect(() => {
    setValue("name", selectedEntity ? selectedEntity.name : "");
    setValue("type", selectedEntity ? selectedEntity.type : "");
    if (selectedEntity && selectedEntity.balance !== undefined)
      setValue("balance", selectedEntity.balance);
    if (selectedEntity && selectedEntity.credit_limit !== undefined)
      setValue("credit_limit", selectedEntity.credit_limit);
    if (selectedEntity && selectedEntity.apr !== undefined)
      setValue("apr", selectedEntity.apr.toString());
    if (selectedEntity && selectedEntity.due_date !== undefined)
      setValue("due_date", selectedEntity.due_date);
  }, [selectedEntity]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    //console.log(data);
    await createEntity({
      id: selectedEntity ? selectedEntity.id : 0,
      name: data.name,
      type: data.type,
      balance: Number(data.balance),
      credit_limit: data.credit_limit,
      apr: data.apr,
      due_date: data.due_date,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      const newData_: iEntity = data.data[0];
      if (selectedEntity && newData_) {
        dispatch(
          editAndUpdateEntity({ id: selectedEntity.id, entity: newData_ })
        );
      } else if (newData_) {
        dispatch(addEntity({ entity: newData_ }));
      }
      setShowNewExpense(!showNewExpense);
      onProgress(100);
      ToastSuccess(
        1,
        `Entity ${selectedEntity ? "updated" : "added"} successfully`
      );
    }
    if (isError) {
      ToastError(
        2,
        `Cannot ${
          selectedEntity ? "update" : "add"
        } entity, please try again: ` + Error
      );
      console.log("Error: ", Error);
    }
  }, [isSuccess, isError]);

  return (
    <div className="">
      <Dialog
        open={showNewExpense}
        handler={setShowNewExpense}
        className="min-w-5/6 sm:min-w-3/4 md:min-w-fit"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader className="text-gray-1000 text-lg text-gray-900">
            {selectedEntity !== undefined
              ? "Update " + selectedEntity.name
              : "New Entity"}
          </DialogHeader>

          <DialogBody divider>
            <div className="w-full text-gray-900 text-sm bg-white m-2">
              <div className="mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5"
                >
                  Name
                </label>
                <input
                  type="text"
                  {...register("name", { required: true })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">{errors?.name?.type}</p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Type
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                    "paymentType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("type", {})}
                >
                  {entityType.map((item, n) => (
                    <option value={n} key={n}>
                      {item}
                    </option>
                  ))}
                </select>
                <p className="text-red-900 text-right">{errors?.type?.type}</p>
              </div>
              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5"
                >
                  Balance
                </label>
                <input
                  type="number"
                  step="0.01"
                  {...register("balance")}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">
                  {errors?.balance?.type}
                </p>
              </div>
              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5"
                >
                  Credit Limit
                </label>
                <input
                  type="number"
                  step="0.01"
                  {...register("credit_limit")}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">
                  {errors?.credit_limit?.type}
                </p>
              </div>
              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5"
                >
                  APR(%)
                </label>
                <input
                  type="number"
                  {...register("apr")}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">{errors?.apr?.type}</p>
              </div>
              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5"
                >
                  Due Date (Day of Month)
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                    "paymentType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("due_date", {})}
                >
                  {Array.from(
                    { length: 32 },
                    (value, key) =>
                      key !== 0 && (
                        <option value={key} key={key}>
                          {key}
                        </option>
                      )
                  )}
                </select>
                <p className="text-red-900 text-right">
                  {errors?.due_date?.type}
                </p>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => setShowNewExpense(!showNewExpense)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="blue" type="submit">
              <span>{selectedEntity ? "Update" : "Add"}</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  );
}

export default AddEntity;
