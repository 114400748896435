import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Currency from "./Currency";
import { iRecurringTransaction } from "../app/interfaces/iRecurringTransaction";
import AddRecurringIncome from "../pages/Income/AddRecurringIncome";
import { iIncomeSource } from "../app/interfaces/iIncomeState";
import { iEntity } from "../app/interfaces/iEntityState";
import { useAppDispatch } from "../app/hooks";
import NewRecurringIncome from "../pages/Income/NewRecurringIncome";
import { selectRecurring } from "../features/recurringSlice";
import { useSelector } from "react-redux";
type props = {
  showNewExpense: boolean;
  setShowNewExpense: (val: boolean) => void;
  incomeSource?: iIncomeSource[];
  entities?: iEntity[];
  onProgress: Dispatch<SetStateAction<number>>;
};

function RecurringIncomeWidget({showNewExpense, setShowNewExpense, incomeSource, entities, onProgress }: props) {
  const [open, setOpen] = useState(false);
  const [openNewRI, setOpenNewRI] = useState(false);
  const [selectedRecurringIncome, setselectedRecurringIncome] = useState<iRecurringTransaction>(Object);
  const handleOpen = () => setOpen(!open);
  const handleOpenNewRI = () => setOpenNewRI(!openNewRI);
  const recurringList = useSelector(selectRecurring);
  const handleRecurringTransaction = (recurringTransaction: iRecurringTransaction) => {
    setselectedRecurringIncome(recurringTransaction);
    setOpen(!open);
  }
  const [recurringIncome,setRecurringIncome] = useState<iRecurringTransaction[]>([]);

  useEffect(() => {
    let rIncome:iRecurringTransaction[] = [];
    recurringList.recurringTransactions.map((item) => {
      if(item.type === "income") {
        let total = 0;
        item.refIncomes && item.refIncomes.map((acc) => {
          total += Number(acc.amount);
        });
        item = Object.assign({}, item, { amount: total });
        rIncome.push(item);
      }
    });
    setRecurringIncome(rIncome);
  },[recurringList]);

  return (
    <div>
      <div className="text-titleColor font-thin flex flex-row justify-between">
        <div>Recurring Income</div>
        <div>
          <span className="text-xs p-1 px-2 font-normal border-[1px] rounded-md shadow-sm text-gray-700 mx-1 cursor-pointer hover:shadow-md hover:text-gray-600 outline-none"
          onClick={handleOpenNewRI}
          >Create</span>
        </div>
      </div>
      <div className="md:pt-4">

          {recurringIncome && recurringIncome.length > 0 && recurringIncome.map((item, n) => (
            <li
              className="flex justify-between my-1 p-1 py-2 border-b-[1px] border-b-gray-200 text-gray-700 text-sm tracking-wide
              hover:bg-gray-50 cursor-pointer"
              key={n}  title={item.expenseType && item.expenseType.name} 
              onClick={() => handleRecurringTransaction(item)}
            >
              <div className="text-sm first-letter:uppercase">{item.incomeSource.name}</div>
              <div className="text-sm">
                <Currency val={item.amount} />
              </div>
          </li>
          ))}
          {recurringIncome.length === 0 && (
            <p className="text-sm text-center font-thin text-gray-600">
              no recurring income yet
            </p>
          )}
      </div>
      <AddRecurringIncome
        showNewExpense={open}
        setShowNewExpense={handleOpen}
        incomeSource={incomeSource}
        entities={entities}
        onProgress={onProgress}
        selectedRecurringIncome={selectedRecurringIncome}
      ></AddRecurringIncome>
      <NewRecurringIncome
        showNewExpense={openNewRI}
        setShowNewExpense={handleOpenNewRI}
        incomeSource={incomeSource}
        entities={entities}
        onProgress={onProgress}
      ></NewRecurringIncome>
    </div>
  );
}

export default RecurringIncomeWidget;

