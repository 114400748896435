export const payrollType = [
  "None",
  "Weekly",
  "Bi Weekly",
  "Semi Monthly",
  "Monthly",
  "Annual",
];

export const entityType = [
  "Cash",
  "Checking",
  "Credit Card",
  "Loan",
  "Saving",
];

export const isCreditOrLoan = (i:string) => {
  if(i === '2' || i === '3')
    return true;
  return false;
}