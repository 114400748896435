import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDIEL5JXe36_FEKYaBbLOSXmekvzf07gTo",
  authDomain: "mohmaya-storage.firebaseapp.com",
  projectId: "mohmaya-storage",
  storageBucket: "mohmaya-storage.appspot.com",
  messagingSenderId: "615716288643",
  appId: "1:615716288643:web:2e40e55277064d643303b6"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export default storage;