import React, { useEffect, useState } from "react";
import "./ToggleSwitch.css";

type props = {
  label: string;
  checked: boolean;
  onChange: any;
};

function ToggleSwitch({ label, checked, onChange }: props) {
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="switch"></span>
      <span className="label">{label}</span>
    </label>
  );
}
export default ToggleSwitch;
