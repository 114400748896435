import { expenseApi } from './../services/expenseApi';
import { configureStore } from '@reduxjs/toolkit'
import { authApi } from '../services/authApi'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import authReducer from '../features/authSlice'
import incomeReducer from '../features/incomeSlice'
import expenseReducer from '../features/xpenseSlice'
import recurringReducer from '../features/recurringSlice'
import entityReducer from '../features/entitySlice'
import { entityApi } from '../services/entityApi';
import { incomeApi } from '../services/incomeApi';
import { recurringApi } from '../services/recurringApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    income: incomeReducer,
    expense: expenseReducer,
    recurring: recurringReducer,
    entity: entityReducer,
    [authApi.reducerPath]: authApi.reducer,
    [expenseApi.reducerPath]: expenseApi.reducer,
    [recurringApi.reducerPath]: recurringApi.reducer,
    [entityApi.reducerPath]: entityApi.reducer,
    [incomeApi.reducerPath]: incomeApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware).concat(expenseApi.middleware).concat(recurringApi.middleware).concat(entityApi.middleware).concat(incomeApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch)

