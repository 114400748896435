import { Button } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { iEntity } from "../../app/interfaces/iEntityState";
import { ToastError, ToastSuccess } from "../../util/Toast";
import { useForm, SubmitHandler } from "react-hook-form";
import { addExpense } from "../../features/xpenseSlice";
import { iTransaction } from "../../app/interfaces/iTransaction";
import { iIncomeSource } from "../../app/interfaces/iIncomeState";
import { useCreateIncomeMutation } from "../../services/incomeApi";
import { updateEntityForIncome } from "../../features/entitySlice";
import { isCreditOrLoan } from "../../app/shared";
import ToggleSwitch from "../../components/ToggleSwitch";
import { useCreateRecurringIncomeMutation } from "../../services/recurringApi";
import { setRecurring } from "../../features/recurringSlice";

interface IFormInput {
  incomeSource: string;
  note: string;
  paymentType_1: string;
  amount_1: number;
  paymentType_2?: string;
  amount_2?: number;
  paymentType_3?: string;
  amount_3?: number;
  date: string;
  accounts: []
}

type Props = {
  showNewExpense: boolean;
  setShowNewExpense: (val: boolean) => void;
  incomeSource?: iIncomeSource[];
  entities?: iEntity[];
  onProgress: Dispatch<SetStateAction<number>>;
};

function NewRecurringIncome({
  showNewExpense,
  setShowNewExpense,
  incomeSource,
  entities,
  onProgress,
}: Props) {
  const dispatch = useAppDispatch();
  const [createRecurringIncome, { data, isSuccess, isError, isLoading, error }] =
  useCreateRecurringIncomeMutation();
  const [payAccountState, setPayAccountState] = useState<any>([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    //console.log(data);
    let payAccounts = [];
    payAccounts.push({paymentMethod: data.paymentType_1, amount: data.amount_1});
    if(data.amount_2)
      payAccounts.push({paymentMethod: data.paymentType_2, amount: data.amount_2});
    if(data.amount_3)
      payAccounts.push({paymentMethod: data.paymentType_3, amount: data.amount_3});
    setPayAccountState(payAccounts);

    await createRecurringIncome({
      incomeSource: data.incomeSource,
      paymentMethod: data.paymentType_1,
      amount: data.amount_1,
      note: data.note,
      date: data.date,
      payAccounts: payAccounts
    });
  };

  useEffect(() => {
    const today = new Date();
    setValue(
      "date",
      today.getUTCFullYear() +
        "-" +
        String(today.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(today.getDate()).padStart(2, "0")
    );
  }, [showNewExpense]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      //updating transaction state
      if (data) {
        console.log('data: ', data)
        dispatch(setRecurring({rTxn: data.data}));
      }
      setShowNewExpense(!showNewExpense);

      onProgress(100);
      ToastSuccess(1, "Recurring Expense added successfully");
    }
    if (isError) {
      ToastError(2, "Cannot add new expense, please try again: " + Error);
      console.log("Error: ", Error);
    }
  }, [isSuccess, isError]);

  return (
    <div className="">
      <Dialog
        open={showNewExpense}
        handler={setShowNewExpense}
        className="min-w-5/6 sm:min-w-3/4 md:min-w-fit"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader className="text-gray-1000 text-lg text-gray-900">
            New Recurring Income
          </DialogHeader>

          <DialogBody divider>
            <div className="w-full text-gray-900 text-sm bg-white m-2">
              <div className="">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Income Source
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "expenseType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("incomeSource", {
                    required: true,
                    maxLength: 100,
                  })}
                >
                  <option value="">Select</option>
                  {incomeSource &&
                    incomeSource.length > 0 &&
                    incomeSource.map((option) => (
                      <option key={option.id} value={option.id.toString()}>
                        {option.name}
                      </option>
                    ))}
                </select>
                <p className="text-red-900 text-right">
                  {errors?.incomeSource?.type}
                </p>
              </div>
              <div className="flex mt-4 gap-x-5">
                <div className="w-1/2">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                  >
                    Account I
                  </label>
                  <select
                    className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                      "paymentType" in errors
                        ? "border-red-900"
                        : " border-gray-400"
                    }`}
                    {...register("paymentType_1", {
                      required: true,
                    })}
                  >
                    <option value="">Select</option>
                    {entities &&
                      entities.length > 0 &&
                      entities.map(
                        (option) =>
                          !isCreditOrLoan(option.type) && (
                            <option key={option.id} value={option.id.toString()}>
                              {option.name}
                            </option>
                          )
                      )}
                  </select>
                  <p className="text-red-900 text-right">
                    {errors?.paymentType_1?.type}
                  </p>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    {...register("amount_1", {
                      required: true,
                      maxLength: 11,
                      pattern: /^\d*\.?\d*$/,
                    })}
                    pattern="[0-9]+([,\.][0-9]+)?" inputMode="decimal"
                    className={`bg-gray-50 border-l-4 text-md block w-full p-2.5 outline-none text-gray-800 font-semibold tracking-wider ${
                      "amount" in errors ? "border-red-900" : " border-gray-400"
                    }`}
                  />
                  <p className="text-red-900 text-right">
                    {errors?.amount_1?.type}
                  </p>
                </div>
              </div>
              <div className="flex mt-4 gap-x-5">
                <div className="w-1/2">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                  >
                    Account II
                  </label>
                  <select
                    className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                      "paymentType" in errors
                        ? "border-red-900"
                        : " border-gray-400"
                    }`}
                    {...register("paymentType_2", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    {entities &&
                      entities.length > 0 &&
                      entities.map(
                        (option) =>
                          !isCreditOrLoan(option.type) && (
                            <option key={option.id} value={option.id.toString()}>
                              {option.name}
                            </option>
                          )
                      )}
                  </select>
                  <p className="text-red-900 text-right">
                    {errors?.paymentType_2?.type}
                  </p>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    {...register("amount_2", {
                      required: false,
                      maxLength: 11,
                      pattern: /^\d*\.?\d*$/,
                    })}
                    pattern="[0-9]+([,\.][0-9]+)?" inputMode="decimal"
                    className={`bg-gray-50 border-l-4 text-md block w-full p-2.5 outline-none text-gray-800 font-semibold tracking-wider ${
                      "amount" in errors ? "border-red-900" : " border-gray-400"
                    }`}
                  />
                  <p className="text-red-900 text-right">
                    {errors?.amount_2?.type}
                  </p>
                </div>
              </div>
              <div className="flex mt-4 gap-x-5">
                <div className="w-1/2">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                  >
                    Account III
                  </label>
                  <select
                    className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                      "paymentType" in errors
                        ? "border-red-900"
                        : " border-gray-400"
                    }`}
                    {...register("paymentType_3", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    {entities &&
                      entities.length > 0 &&
                      entities.map(
                        (option) =>
                          !isCreditOrLoan(option.type) && (
                            <option key={option.id} value={option.id.toString()}>
                              {option.name}
                            </option>
                          )
                      )}
                  </select>
                  <p className="text-red-900 text-right">
                    {errors?.paymentType_3?.type}
                  </p>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor=""
                    className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    {...register("amount_3", {
                      required: false,
                      maxLength: 11,
                      pattern: /^\d*\.?\d*$/,
                    })}
                    pattern="[0-9]+([,\.][0-9]+)?" inputMode="decimal"
                    className={`bg-gray-50 border-l-4 text-md block w-full p-2.5 outline-none text-gray-800 font-semibold tracking-wider ${
                      "amount" in errors ? "border-red-900" : " border-gray-400"
                    }`}
                  />
                  <p className="text-red-900 text-right">
                    {errors?.amount_3?.type}
                  </p>
                </div>
              </div>
              
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Note
                </label>
                <textarea
                  id=""
                  {...register("note", { required: true, maxLength: 100 })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "note" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                ></textarea>
                <p className="text-red-900 text-right">{errors?.note?.type}</p>
              </div>
              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Date
                </label>
                <input
                  type="date"
                  {...register("date", { required: true })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">{errors?.date?.type}</p>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => setShowNewExpense(!showNewExpense)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="blue" type="submit">
              <span>Add</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  );
}

export default NewRecurringIncome;
