import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectEntity } from "../../features/entitySlice";
import { toFullMonth } from "../../constants/date";
import { formatDay } from "../../util/Common";
import { selectExpense } from "../../features/xpenseSlice";
import { iTransaction } from "../../app/interfaces/iTransaction";
import { iEntity } from "../../app/interfaces/iEntityState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

type props = {};

function DueWidget({}: props) {
  const entity = useSelector(selectEntity);
  const expense = useSelector(selectExpense);
  let expenseListAll: iTransaction[] = expense.expenses;

  const [dueEntities, setDueEntities] = useState<iEntity[]>(
    entity.entities.filter((item) => {
      if (item.balance && item.balance > 0 && item.due_date) {
        return item;
      }
    })
  );

  const currentMonth = new Date().getUTCMonth();

  useEffect(() => {
    // check if payment has already made
    const dueSoon = dueEntities.map((entity) => {
      let res = false;
      expenseListAll.forEach((expns) => {
        const createdAt = new Date(expns.createdAt);
        if (
          entity.id === expns.financialEntityId &&
          createdAt.getUTCMonth() === currentMonth
        ) {
          res = true;
        }
      });
      return Object.assign({}, entity, { paidThisMonth: res });
    });

    setDueEntities(dueSoon);
  }, []);

  return (
    <>
      <div className="py-1.5 md:pl-3 md:py-2.5">
        <div className="bg-white rounded-lg shadow-sm p-3 md:p-5 ring-1 ring-bdColor">
          <div className="text-titleColor  font-thin text-sm">Due Soon</div>

          <div className="py-2">
            {dueEntities &&
              dueEntities.length > 0 &&
              dueEntities.map((item, i) => (
                <div
                  className="flex mt-1 border-b-[1px] border-b-gray-200 text-gray-700 text-sm tracking-wide py-2"
                  key={i}
                >
                  <div>
                    {item.paidThisMonth ? (
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="text-blueColor text-lg"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="text-gray-300 text-lg"
                      />
                    )}
                  </div>
                  <div className="flex w-full flex-row justify-between px-3">
                    <div className="text-sm font-thin">{item.name}</div>
                    <div className="text-gray-600 font-thin text-xs pt-1">
                      {formatDay(item.due_date)}
                      {Number(item.due_date) > currentMonth
                        ? " " + toFullMonth[new Date().getMonth()]
                        : new Date().getMonth() + 1 < 12
                        ? " " + toFullMonth[new Date().getMonth() + 1]
                        : " " + toFullMonth[0]}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default DueWidget;
