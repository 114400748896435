import { iEntity, iEntityState } from './../app/interfaces/iEntityState';
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import { isCreditOrLoan } from '../app/shared';


const initialState:iEntityState = {
  entities: []
}

export const entitySlice = createSlice({
  name: 'entity',
  initialState: initialState,
  reducers: {
    setEntity: (state, action: PayloadAction<{entities: iEntity[]}>) => {
      state.entities = action.payload.entities
    },

    addEntity: (state, action: PayloadAction<{entity: iEntity}>) => {
      state.entities.unshift(action.payload.entity)
    },

    editAndUpdateEntity: (state, action: PayloadAction<{id: number, entity: iEntity}>) => {
      const updatedEnities = state.entities.map((item, i) => {
        if(item.id === action.payload.id) {
          item = action.payload.entity;
        }
        return item;
      })
      state.entities = updatedEnities;
    },

    updateEntity: (state, action: PayloadAction<{id: number, balance: number}>) => {
      const updatedEnities = state.entities.map((item, i) => {
        if(item.id === action.payload.id) {
          if(isCreditOrLoan(item.type))
            item.balance = Number(item.balance) + action.payload.balance;
          else 
            item.balance = Number(item.balance) - action.payload.balance;
        }
        return item;
      })
      state.entities = updatedEnities;
    },

    updateEntityForTransfer: (state, action: PayloadAction<{fromId: number, toId: number, balance: number}>) => {
      const updatedEnities = state.entities.map((item, i) => {
        if(item.id === action.payload.fromId) {
            item.balance = Number(item.balance) - action.payload.balance;
        }
        if(item.id === action.payload.toId) {
          if(isCreditOrLoan(item.type)) {
            item.balance = Number(item.balance) - action.payload.balance;
          } else {
            item.balance = Number(item.balance) + action.payload.balance;
          }
        }
        return item;
      })
      state.entities = updatedEnities;
    },

    updateEntityForIncome: (state, action: PayloadAction<{payAccounts: [{paymentMethod:number, amount: number}]}>) => {
      action.payload.payAccounts.forEach((pa) => {
        const updatedEnities = state.entities.map((item, i) => {
          if(Number(item.id) === Number(pa.paymentMethod)) {
            item.balance = Number(item.balance) + Number(pa.amount);
          }
          return item;
        })
        state.entities = updatedEnities;
      });
    },
  }
})

export const selectEntity = (state: RootState) => state.entity
export const { setEntity, addEntity, updateEntity, updateEntityForTransfer, updateEntityForIncome, editAndUpdateEntity } = entitySlice.actions
export default entitySlice.reducer