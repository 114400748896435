import { Button } from "@material-tailwind/react";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import UploadProfileImage from "../../components/UploadProfileImage";

interface IFormInput {
  password: string;
  cpassword: string;
}

const AccountSettings = ({
  onProgress,
}: {
  onProgress: Dispatch<SetStateAction<number>>;
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  return (
    <div className="bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] boder-bdColor">
      <div className="text-titleColor font-thin ">Manage Account</div>
      <div className="md:flex">
        <div className="my-10 md:w-1/3">
          <UploadProfileImage />
        </div>
        <div className="my-10 md:w-3/4">
          <div className="text-titleColor font-light ">Change Password</div>
          <div className="w-full text-gray-900 text-sm bg-white my-5">
            <div className="mb-2">
              <label
                htmlFor=""
                className="text-sm font-bold text-gray-600 block mb-1.5"
              >
                Password
              </label>
              <input
                type="password"
                {...register("password", { required: true })}
                className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                  "date" in errors ? "border-red-900" : " border-gray-400"
                }`}
              />
              <p className="text-red-900 text-right">
                {errors?.password?.type}
              </p>
            </div>
            <div className="mb-2">
              <label
                htmlFor=""
                className="text-sm font-bold text-gray-600 block mb-1.5"
              >
                Confirm Password
              </label>
              <input
                type="password"
                {...register("cpassword", { required: true })}
                className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                  "date" in errors ? "border-red-900" : " border-gray-400"
                }`}
              />
              <p className="text-red-900 text-right">
                {errors?.cpassword?.type}
              </p>
            </div>
            <div className="my-5 text-center">
              <Button variant="gradient" color="blue" type="submit" size="sm">
                <span>Change Password</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
