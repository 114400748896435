import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../app/mohmayaApi';

export const recurringApi = createApi({
  reducerPath: 'recurringApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({

    createRecurringExpense: builder.mutation({
      query: (body: {paymentMethod:string, expenseType:string, amount:number, note:string, date: string, recurringType: string}) => {
        return {
          url: '/recurring/add-expense',
          method: 'post',
          body
        }
      }
    }),
    getRecurringTransactions: builder.mutation({
      query: (body: { year: number}) => {
        return {
          url: '/recurring-txns',
          method: 'post',
          body
        }
      }
    }),
    removeRecurringTransactions: builder.mutation({
      query: (body: { id: number}) => {
        return {
          url: '/remove-recurring-txns',
          method: 'post',
          body
        }
      }
    }),
    createRecurringIncome: builder.mutation({
      query: (body: {paymentMethod:string, incomeSource:string, amount:number, note:string, date: string, payAccounts: any[]}) => {
        return {
          url: '/recurring/add-income',
          method: 'post',
          body
        }
      }
    }),
  }),
})


export const { useCreateRecurringExpenseMutation, useGetRecurringTransactionsMutation, useRemoveRecurringTransactionsMutation, useCreateRecurringIncomeMutation } = recurringApi;