function YearsList(): JSX.Element {
  let thisYear = new Date().getUTCFullYear();
  let allYears = [];
  for (let x = thisYear; x >= 2016; x--) {
    allYears.push(x);
  }
  return (
    <>
      {allYears.map((year) => (
        <option key={year}>{year.toString()}</option>
      ))}
    </>
  );
}

export default YearsList;
