import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../app/mohmayaApi';

export const expenseApi = createApi({
  reducerPath: 'expenseApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createExpenseType: builder.mutation({
      query: (body: {id: number, expenseType:string, parent: number, in_trends: number}) => {
        return {
          url: '/expense-type/add',
          method: 'post',
          body
        }
      }
    }),
    getExpenseTypes: builder.mutation({
      query: () => {
        return {
          url: '/expense-types',
          method: 'get',
        }
      }
    }),
    createExpense: builder.mutation({
      query: (body: {paymentMethod:string, expenseType:string, amount:number, note:string, date: string}) => {
        return {
          url: '/expense/add',
          method: 'post',
          body
        }
      }
    }),
    getExpenses: builder.mutation({
      query: (body: { year: number}) => {
        return {
          url: '/expenses',
          method: 'post',
          body
        }
      }
    }),
    createTransfer: builder.mutation({
      query: (body: {from:string, to:string, amount:number, note:string, date: string}) => {
        return {
          url: '/transfer/add',
          method: 'post',
          body
        }
      }
    }),
  }),
})


export const { useCreateExpenseTypeMutation, useGetExpenseTypesMutation, useCreateExpenseMutation, useGetExpensesMutation, useCreateTransferMutation } = expenseApi;