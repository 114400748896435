import { Button } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { iExpenseType } from "../../app/interfaces/iExpenseState";
import { iEntity } from "../../app/interfaces/iEntityState";
import { useCreateExpenseMutation } from "../../services/expenseApi";
import { ToastError, ToastSuccess } from "../../util/Toast";
import { useForm, SubmitHandler } from "react-hook-form";
import { addExpense } from "../../features/xpenseSlice";
import { iTransaction } from "../../app/interfaces/iTransaction";
import { updateEntity } from "../../features/entitySlice";
import ToggleSwitch from "../../components/ToggleSwitch";

interface IFormInput {
  expenseType: string;
  amount: number;
  note: string;
  paymentType: string;
  date: string;
}

type Props = {
  showNewExpense: boolean;
  setShowNewExpense: (val: boolean) => void;
  exTypes?: iExpenseType[];
  entities?: iEntity[];
  onProgress: Dispatch<SetStateAction<number>>;
};

function AddExpense({
  showNewExpense,
  setShowNewExpense,
  exTypes,
  entities,
  onProgress,
}: Props) {
  const dispatch = useAppDispatch();
  const [createExpense, { data, isSuccess, isError, isLoading, error }] =
    useCreateExpenseMutation();
  const [entityIdForEntityState, setEntityIdForEntityState] = useState(0);
  const [amountForEntityState, setAmountForEntityState] = useState(0);
  const [repeatForm, setRepeatForm] = useState(false);
  const [recurringSwitch, setRecurringSwitch] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setEntityIdForEntityState(Number(data.paymentType));
    setAmountForEntityState(Number(data.amount));
    //console.log(data);
    await createExpense({
      paymentMethod: data.paymentType,
      expenseType: data.expenseType,
      amount: data.amount,
      note: data.note,
      date: data.date,
    });
  };

  useEffect(() => {
    if (!repeatForm) {
      const today = new Date();
      setValue(
        "date",
        today.getUTCFullYear() +
          "-" +
          String(today.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(today.getDate()).padStart(2, "0")
      );
    }
  }, [showNewExpense]);

  useEffect(() => {
    if (isSuccess) {
      if (!repeatForm) {
        reset();
      }
      //update state
      if (data) {
        const newData_: iTransaction = data.data[0];
        dispatch(addExpense({ expense: newData_ }));
      }
      setShowNewExpense(!showNewExpense);
      // Update State of Financial Entity
      dispatch(
        updateEntity({
          id: entityIdForEntityState,
          balance: amountForEntityState,
        })
      );

      onProgress(100);
      ToastSuccess(1, "Expense added successfully");
    }
    if (isError) {
      ToastError(2, "Cannot add new expense, please try again: " + Error);
      console.log("Error: ", Error);
    }
  }, [isSuccess, isError]);

  const amountCalc = (e: any) => {
    e.preventDefault();
    const re = /^[0-9\b+-.]+/;
    const val = e.target.value;

    if (re.test(val[val.length - 1])) {
      setValue("amount", val);
    } else {
      setValue("amount", val.slice(0, -1));
    }

    if (val[val.length - 1] === " ") {
      const trimmed = val.trim();
      let s;
      let res = 0;
      if (trimmed.includes("+")) {
        s = trimmed.split("+");
        s.map((n: any) => {
          res += parseFloat(n);
        });
      } else if (trimmed.includes("-")) {
        s = trimmed.split("-").reverse();
        s.map((n: any) => {
          res = parseFloat(n) - res;
        });
      }
      setValue("amount", res);
    }
  };

  return (
    <div className="">
      <Dialog
        open={showNewExpense}
        handler={setShowNewExpense}
        className="min-w-5/6 sm:min-w-3/4 md:min-w-fit"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader className="text-gray-1000 text-lg text-gray-900">
            New Expense
          </DialogHeader>

          <DialogBody divider>
            <div className="w-full text-gray-900 text-sm bg-white m-2">
              <div className="">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Expense Type
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none rounded-none ${
                    "expenseType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("expenseType", {
                    required: true,
                    maxLength: 100,
                  })}
                >
                  <option value="">Select</option>
                  {exTypes &&
                    exTypes.length > 0 &&
                    exTypes.map((option) => (
                      <option key={option.id} value={option.id.toString()}>
                        {option.name}
                      </option>
                    ))}
                </select>
                <p className="text-red-900 text-right">
                  {errors?.expenseType?.type}
                </p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Amount
                </label>
                <input
                  type="text"
                  step="0.01"
                  {...register("amount", {
                    required: true,
                    maxLength: 11,
                  })} 
                  pattern="[0-9]+([,\.][0-9]+)?" inputMode="decimal"
                  className={`bg-gray-50 border-l-4 text-md block w-full p-2.5 outline-none text-gray-800 font-semibold tracking-wider rounded-none ${
                    "amount" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                  onKeyUp={(event) => amountCalc(event)}
                />
                <p className="text-red-900 text-right">
                  {errors?.amount?.type}
                </p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Note
                </label>
                <textarea
                  id=""
                  {...register("note", { required: true, maxLength: 100 })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none rounded-none ${
                    "note" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                ></textarea>
                <p className="text-red-900 text-right">{errors?.note?.type}</p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Payment
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none rounded-none ${
                    "paymentType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("paymentType", {
                    required: true,
                  })}
                >
                  <option value="">Select</option>
                  {entities &&
                    entities.length > 0 &&
                    entities.map((option) => (
                      <option key={option.id} value={option.id.toString()}>
                        {option.name}
                      </option>
                    ))}
                </select>
                <p className="text-red-900 text-right">
                  {errors?.paymentType?.type}
                </p>
              </div>
              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Date
                </label>
                <input
                  type="date"
                  {...register("date", { required: true })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none rounded-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">{errors?.date?.type}</p>
              </div>
              <div className="mt-4 mb-2">
                <ToggleSwitch
                  label="Repeat"
                  checked={repeatForm}
                  onChange={() => {
                    setRepeatForm(!repeatForm);
                  }}
                />
              </div>
              <div className="flex justify-between">
                <div className="mt-2 mb-2">
                  <ToggleSwitch
                    label="Recurring"
                    checked={recurringSwitch}
                    onChange={() => {
                      setRecurringSwitch(!recurringSwitch);
                    }}
                  />
                </div>
                {recurringSwitch && (
                  <div>
                    <select
                      className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none rounded-none ${
                        "paymentType" in errors
                          ? "border-red-900"
                          : " border-gray-300"
                      }`}
                    >
                      <option value="">Recurrence Pattern</option>
                      <option value="1">Once in a year</option>
                      <option value="2">Daily</option>
                      <option value="3">Weekly</option>
                      <option value="4">Monthly</option>
                      <option value="5">Yearly</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => setShowNewExpense(!showNewExpense)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="blue" type="submit">
              <span>Add</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  );
}

export default AddExpense;
