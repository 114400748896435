import { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  useLoginUserMutation,
  useRegisterUserMutation,
} from "../../services/authApi";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth, setUser } from "../../features/authSlice";
import { ToastError, ToastSuccess } from "../../util/Toast";
import { Input, Button } from "@material-tailwind/react";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  password: "",
  confirmPassword: "",
};

const Auth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = useAppSelector(selectAuth);

  const [formValue, setFormValue] = useState(initialState);
  const { firstName, lastName, email, username, password, confirmPassword } =
    formValue;

  const [showRegister, setShowRegister] = useState(false);
  const [
    loginUser,
    {
      data: loginData,
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      isLoading: isLoginLoading,
    },
  ] = useLoginUserMutation();
  const [
    registerUser,
    {
      data: registerData,
      isSuccess: isRegsiterSuccess,
      isError: isRegisterError,
      isLoading: isRegisterLoading,
    },
  ] = useRegisterUserMutation();

  const dispatch = useAppDispatch();

  const handleChange = (e: any) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (showRegister) handleRegister();
    else handleLogin();
  };

  const handleLogin = async () => {
    if (username && password) {
      await loginUser({ username, password });
    } else {
      ToastError(1, "Please fill all input fields");
    }
  };

  const handleRegister = async () => {
    if (
      username &&
      password &&
      firstName &&
      lastName &&
      email &&
      confirmPassword
    ) {
      if (password !== confirmPassword) {
        ToastError(1, "Password should be matched with confirm password");
        return;
      }
      await registerUser({ firstName, lastName, email, username, password });
    } else {
      ToastError(2, "Please fill all input fields");
    }
  };

  useEffect(() => {
    if (isLoginSuccess) {
      //ToastSuccess(1,'User login successfully')
      console.log("loginData", loginData);
      dispatch(
        setUser({
          id: loginData.id,
          name: loginData.firstname,
          token: loginData.accessToken,
          refreshToken: loginData.refreshToken,
        })
      );
      navigate("/Dashboard");
    }
    if (isLoginError) {
      ToastError(2, "Cannot login, please try again");
    }
  }, [isLoginSuccess, isLoginError]);

  useEffect(() => {
    if (isRegsiterSuccess) {
      if (registerData.success === 1) {
        ToastSuccess(1, "Register successfully");
        setShowRegister(false);
        navigate("/auth");
      } else {
        ToastError(2, registerData.message);
      }
    }
    if (isRegisterError) {
      ToastError(3, "Cannot register user, please try again");
    }
  }, [isRegsiterSuccess, isRegisterError]);

  return name ? (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  ) : (
    <div className="h-screen bg-gradient-to-t from-blue-200 min-w-fit">
      <div className="flex min-h-screen flex-col justify-center">
        <div className="mx-auto min-w-fit max-w-lg w-5/6 sm:w-5/6 md:w-3/6 bg-white shadow-xl ring-1 ring-gray-900/5 rounded-lg px-6 py-8">
          <h1 className="text-center text-3xl text-blue-600 font-semibold tracking-wide py-2">
            Mohmaya.
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="pt-3 sm:pt-6">
              {showRegister && (
                <>
                  <div className="">
                    <Input
                        type="text"
                        name="firstName"
                        label="First name"
                        value={firstName}
                        onChange={handleChange}
                        className="form-control form-control-lg"
                        size="lg" crossOrigin={undefined}                    />
                  </div>
                  <div className="pt-5">
                    <Input
                      type="text"
                      name="lastName"
                      label="Last name"
                      value={lastName}
                      onChange={handleChange}
                      className="form-control form-control-lg"
                      size="lg" crossOrigin={undefined} 
                    />
                  </div>
                  <div className="pt-5">
                    <Input
                      type="text"
                      name="email"
                      label="Email"
                      value={email}
                      onChange={handleChange}
                      className="form-control form-control-lg"
                      size="lg" crossOrigin={undefined} 
                    />
                  </div>
                </>
              )}

              <div className="pt-5">
                <Input
                  type="text"
                  name="username"
                  label="Username"
                  value={username}
                  onChange={handleChange}
                  className="form-control form-control-lg"
                  size="lg" crossOrigin={undefined} 
                />
              </div>
              <div className="pt-5">
                <Input
                  type="password"
                  name="password"
                  label="Password"
                  value={password}
                  onChange={handleChange}
                  className="form-control form-control-lg"
                  size="lg" crossOrigin={undefined} 
                />
              </div>

              {showRegister && (
                <>
                  <div className="pt-5">
                    <Input
                      type="password"
                      name="confirmPassword"
                      label="Confirm Password"
                      value={confirmPassword}
                      onChange={handleChange}
                      className="form-control form-control-lg"
                      size="lg" crossOrigin={undefined} 
                    />
                  </div>
                </>
              )}

              {!showRegister ? (
                <div className="pt-8 text-center">
                  <Button
                    type="submit"
                    color="blue"
                    className="w-1/2"
                    disabled={isLoginLoading}
                  >
                    Login
                  </Button>
                </div>
              ) : (
                <div className="pt-8 text-center">
                  <Button
                    type="submit"
                    color="blue"
                    className="w-1/2"
                    disabled={isRegisterLoading}
                  >
                    Register
                  </Button>
                </div>
              )}
            </div>
          </form>

          <div>
            <div className="py-5 relative text-center flex flex-row justify-center">
              <div className="border-b-2 border-gray-200 w-3/5 h-3"></div>
              <div className=" w-1/6">
                <div className="text-gray-500">OR</div>
              </div>
              <div className="border-b-2 border-gray-200 w-3/5 h-3"></div>
            </div>
            <div className="text-center">
              {!showRegister ? (
                <Button
                  type="button"
                  variant="outlined"
                  className="w-1/2 outline-none"
                  onClick={() => setShowRegister(true)}
                >
                  Register
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="outlined"
                  className="w-1/2 outline-none"
                  onClick={() => setShowRegister(false)}
                >
                  Login
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
