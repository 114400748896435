import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../app/mohmayaApi';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body: {username:string, password:string}) => {
        return {
          url: '/login',
          method: 'post',
          body
        }
      }
    }),
    registerUser: builder.mutation({
      query: (body: {firstName:string, lastName:string, email:string, username:string, password:string}) => {
        return {
          url: '/register',
          method: 'post',
          body
        }
      }
    }),
    refreshToken: builder.mutation({
      query: (body: {refreshToken:string}) => {
        return {
          url: '/refreshToken',
          method: 'get',
          body
        }
      }
    })
  }),
  
})

export const { useLoginUserMutation, useRegisterUserMutation, useRefreshTokenMutation } = authApi;